import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import CourseDetails from "./CourseDetails";
import NewCourse from "./NewCourse";
import SecretariatLandingPage from "./SecretariatLandingPage";
import EvaluationReport from "./EvaluationReports";
import ParticipationRecords from "./ParticipationRecords";
import SecretariatCourseDetails from "./SecretariatCourseDetails";
import Login from "./Login";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import SecretariatCourseLists from "./SecretariatCourseLists";
import TrainingProviderLandingPage from "./TrainingProviderLandingPage";

const Routing = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
              <Route path="/tpCourseDetails" element={<CourseDetails />}></Route>
              <Route path="/newCourse" element={<NewCourse />}></Route>
              <Route
                path="/secretariatView"
                element={<SecretariatLandingPage />}></Route>
              <Route path="/reports" element={<EvaluationReport />}></Route>
              <Route
                path="/participationRecords"
                element={<ParticipationRecords />}></Route>
              <Route
                path="/secretariatCourseDetails"
                element={<SecretariatCourseDetails />}></Route>
                <Route
                path="/managerView"
                element={<SecretariatLandingPage />}></Route>
                <Route
                path="/managerViewDetails"
                element={<SecretariatCourseDetails />}></Route>
                <Route
                path="/headView"
                element={<SecretariatLandingPage />}></Route>
                <Route
                path="/headViewDetails"
                element={<SecretariatCourseDetails />}></Route>
                <Route
                path="/wcView"
                element={<SecretariatLandingPage />}></Route>
                <Route
                path="/wcViewDetails"
                element={<SecretariatCourseDetails />}></Route>
                <Route
                path="/courseLists"
                element={<SecretariatCourseLists />}></Route>
                <Route
                path="/trainingProviderView"
                element={<TrainingProviderLandingPage />}></Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Routing;
